<template>
  <div>

    <validation-observer
      tag="div"
      v-for="(item, itemIndex) in vItems"
      :ref="`${slotConfig.name}_${itemIndex}`"
      :vid="`${slotConfig.name}_${itemIndex}`"
      :key="item.bleID"
      class="mb-05"
      #default="{ errors: voErrors }"
    >
      <!--<div>{{ voErrors }}</div>-->
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="flex-grow-1 d-flex">
            <b-input-group :id="`${slotConfig.name}_${itemIndex}_itemText`">
              <b-form-input
                type="text"
                :value="vItemsText[itemIndex]"
                :class="slotConfig.input_class"
                style="height:100%;background-color:transparent;"
                v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                readonly/>
              <b-input-group-append>
                <b-button
                  v-b-modal="`${slotConfig.name}_${itemIndex}_modal`"
                  variant="outline-primary"
                  style="height:100%;"
                >
                  <feather-icon icon="EditIcon"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <b-button
            v-if="slotConfig.show_del_btn"
            class="flex-shrink-0 ml-1"
            variant="outline-danger"
            @click="deleteItem(itemIndex)"
            :disabled="vItems.length <= slotConfig.min_count"
          >
            <!--{{ $t('common.delete') }}-->
            <feather-icon icon="MinusCircleIcon"/>
          </b-button>
        </b-col>

        <!-- 檢測當前 item 是否選擇了院舍，及填寫了 NFC -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_exist`] && voErrors[`${slotConfig.name}_${itemIndex}_exist`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_exist`"
          :vid="`${slotConfig.name}_${itemIndex}_exist`"
          :name="$t(slotConfig.label)"
          :customMessages="slotConfig.custom_messages"
          :rules="{ required: true }"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="(
                item.value.serialNumber
                && item.value.facilityID > -1
                && facilityAndRoleList.map(f => f.facilityID).includes(item.value.facilityID)
              ) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item NFC 是否符合規則 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_regexp`] && voErrors[`${slotConfig.name}_${itemIndex}_regexp`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_regexp`"
          :vid="`${slotConfig.name}_${itemIndex}_regexp`"
          :name="$t(slotConfig.label)"
          :customMessages="null"
          :rules="{ regex: /^(?:[A-Fa-f0-9]{2}:){2,7}[A-Fa-f0-9]{2}$|^(?:[A-Fa-f0-9]{2}){3,8}$/ }"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="item.value.serialNumber ? item.value.serialNumber : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

        <!-- 檢測當前 item 與其他 items 之間是否存在重複 NFC -->
        <!--<validation-provider-->
        <!--  tag="div"-->
        <!--  #default="{ errors }"-->
        <!--  class="validation-provider"-->
        <!--  :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_duplicateNfc`] && voErrors[`${slotConfig.name}_${itemIndex}_duplicateNfc`].length > 0 }"-->
        <!--  :ref="`${slotConfig.name}_${itemIndex}_duplicateNfc`"-->
        <!--  :vid="`${slotConfig.name}_${itemIndex}_duplicateNfc`"-->
        <!--  :name="$t(slotConfig.label)"-->
        <!--  :customMessages="{ length: slotConfig.duplicate_tips}"-->
        <!--  rules="length:0"-->
        <!--  v-if="slotConfig.check_duplicate"-->
        <!--&gt;-->
        <!--  <b-col cols="12" v-show="errors.length > 0">-->
        <!--    <input type="hidden" :value="duplicateNfcs.includes(vItems[itemIndex].value.serialNumber) ? '_' : ''"/>-->
        <!--    <small class="text-danger">{{ errors[0] }}</small>-->
        <!--  </b-col>-->
        <!--</validation-provider>-->

        <!-- 檢測當前 item 與其他 items 之間是否存在重複院舍 -->
        <validation-provider
          tag="div"
          #default="{ errors }"
          class="validation-provider"
          :class="{ error: voErrors[`${slotConfig.name}_${itemIndex}_duplicateFacility`] && voErrors[`${slotConfig.name}_${itemIndex}_duplicateFacility`].length > 0 }"
          :ref="`${slotConfig.name}_${itemIndex}_duplicateFacility`"
          :vid="`${slotConfig.name}_${itemIndex}_duplicateFacility`"
          :name="$t(slotConfig.label)"
          :customMessages="{ length: '重複的 院舍' }"
          rules="length:0"
          v-if="slotConfig.check_duplicate"
        >
          <b-col cols="12" v-show="errors.length > 0">
            <input type="hidden" :value="duplicateFacilities.includes(vItems[itemIndex].value.facilityID) ? '_' : ''"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-col>
        </validation-provider>

      </b-row>


      <b-modal
        :id="`${slotConfig.name}_${itemIndex}_modal`"
        :title="`${$t('common.edit')} - ${$t(slotConfig.label)}`"
        size="lg"
        :ok-title="$t('common.confirm')"
        centered no-fade no-close-on-backdrop ok-only static
      >
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_facility`">
              {{ $t('common.facility') }} <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <v-select
              :id="`${slotConfig.name}_${itemIndex}_facility`"
              label="text"
              :options="facilityOptions"
              v-model="item.value.facilityID"
              :reduce="text => text.value"
              :clearable="false"
              class="mb-1"
              @input="onModelTagChange(itemIndex, arguments[0])"
            >
              <template #no-options>{{ $t('common.no_options') }}</template>
            </v-select>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12" md="4" lg="3">
            <label class="field-row-label" :for="`${slotConfig.name}_${itemIndex}_sn`">
              {{ $t('common.nfc_tag') }} <span style="color: red">*</span>
            </label>
          </b-col>
          <b-col cols="12" md="8" lg="9">
            <b-form-input
              :id="`${slotConfig.name}_${itemIndex}_sn`"
              :list="`${slotConfig.name}_${itemIndex}_snList`"
              v-model="item.value.serialNumber"
              type="text"/>
          </b-col>
        </b-row>
      </b-modal>

    </validation-observer>

    <b-button
      v-if="slotConfig.show_add_btn" variant="outline-primary" @click="addItem()"
      :disabled="vItems.length >= slotConfig.max_count"
    >
      <!--{{ $t('common.add') }}-->
      <feather-icon icon="PlusCircleIcon"/>
    </b-button>

  </div>
</template>

<script>
import common from '@/common'
import kioskUtils from '@/libs/kiosk-utils'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import '@core/utils/validations/validations'
import { getDuplicateItems, truncateString } from '@/libs/ez-utils'

export default {
  name: 'FieldModalCallBellNfcs',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    rowData: { type: Object },
    input_class: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    rootItem: {
      type: Object,
      default: () => ({}),
    },
    facilityAndRoleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      autoIndex: 0,
      vItems: [], // 存放 QRCode 對象
      vItemDefaultValue: { // modelItem 的空值
        serialNumber: '',
        facilityID: undefined,
      },
      slotConfig: Object.assign({
        // type: 'text',
        name: 'defaultName',
        label: 'defaultLabel',
        min_count: this.rootItem.rule?.split('|')
          .includes('required') ? 1 : 0,
        max_count: 1,
        check_duplicate: true,
        duplicate_tips: this.$t('common.duplicate_content_tips'),
        show_del_btn: false,
        show_add_btn: false,
      }, this.rootItem.slot_config),
      modalRules: {
        facilityID: 'required',
        nfc: {
          required: true,
          regex: /^(?:[A-Fa-f0-9]{2}:){2,7}[A-Fa-f0-9]{2}$|^(?:[A-Fa-f0-9]{2}){3,8}$/
        }
      },
    }
  },

  computed: {
    vItemsText() {
      return this.vItems.map(item => {
        const serialNumber = truncateString(item.value.serialNumber ?? '', 20, 3).padEnd(20, ' ')
        const facilityName = this.facilityAndRoleList.find(f => f.facilityID === item.value.facilityID)?.facilityName ?? ''
        return facilityName + ' | ' + serialNumber
      })
    },
    facilityOptions: {
      get() {
        return common.getSelectOptions(this.facilityAndRoleList, 'facilityName', 'facilityID')
      }
    },
    duplicateNfcs: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItems.map(item => item.value.serialNumber))
          : []
      },
    },
    duplicateFacilities: {
      get() {
        return this.slotConfig.check_duplicate
          ? getDuplicateItems(this.vItems.map(item => item.value.facilityID))
          : []
      },
    },
  },

  watch: {
    items: {
      handler(items, _oldValues) {
        // 當 items 的值發生變化時，會先刪除多餘的 modelItems，再新增缺少的 modelItems
        for (const [i, item] of items.entries()) {
          while (this.vItems.length >= i + 1 && !_.isEqual(item, this.vItems[i].value)) {
            this.deleteItem(i, false)
          }
          if (this.vItems.length < i + 1) {
            this.addItem(_.clone(item))
          }
        }
      },
      deep: true,
    },
  },

  methods: {
    addItem(value, isUpdate = true) {
      this.vItems.push({
        value: value === undefined ? _.clone(this.vItemDefaultValue) : value,
        id: this.autoIndex,
      })
      this.autoIndex += 1
      isUpdate && this.updateValues()
    },
    deleteItem(itemIndex, isUpdate = true) {
      this.vItems.splice(itemIndex, 1)
      isUpdate && this.updateValues()
    },
    onModelTagChange(itemIndex, modelTag) {
      // console.log(itemIndex, this.vItems[itemIndex].value)
    },
    updateValues(itemIndex) {
      this.$emit('updateValues', this.vItems.map(item => item.value))
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.field-row-label {
  padding: calc(0.438rem + 1px) 0;
}

// 只显示第一个 .error
.validation-provider {
  display: none;

  &.error {
    display: block;
  }

  &.error ~ &.error {
    display: none;
  }
}
</style>
